import { HexSLTypes, NumericMeasureAggTypes, titleCase } from "../index.js";

export function semanticDatasetTitleString(dataset: {
  name: string;
  title?: string | null;
}): string {
  return dataset.title || dataset.name;
}

export function semanticMeasureTitleString(measure: {
  name: string;
  title?: string | null;
}): string {
  return measure.title || measure.name;
}

export function semanticDimensionTitleString(dimension: {
  name: string;
  title?: string | null;
}): string {
  return dimension.title || dimension.name;
}

export function semanticMeasureDisplayExpression(
  measure: HexSLTypes.Measure,
): string {
  const expression: string =
    measure.sql ?? (typeof measure.calc === "string" ? measure.calc : "");
  if (!expression) {
    return "";
  }

  // This is not necessarily a valid SQl/calc expression, but we want to include
  // the aggregation type when displaying the sql expression.
  if (NumericMeasureAggTypes.guard(measure.type)) {
    return `${titleCase(measure.type)}(${expression})`;
  }

  return expression;
}

export function semanticDimensionDisplayExpression(
  dimension: HexSLTypes.Dimension,
): {
  expression: string;
  label: string;
} {
  if (dimension.sql != null || dimension.calc != null) {
    const expression: string =
      dimension.sql ??
      (typeof dimension.calc === "string" ? dimension.calc : "");

    return {
      expression,
      label: "Expression",
    };
  }

  return {
    expression: dimension.column || dimension.name,
    label: "Column",
  };
}

export function semanticMeasureFilterDisplayExpression(
  filter: HexSLTypes.MeasureFilter,
): string | null {
  if (filter.calc != null) {
    if (typeof filter.calc === "string") {
      return filter.calc;
    } else {
      // This is a terrible way to display things but should work for now
      return JSON.stringify(filter.calc, null, 2);
    }
  }

  if (filter.sql) {
    return (
      filter.sql
        // "${DATASET}.foo" -> "foo"
        .replaceAll("${DATASET}.", "")
        // "${DATASET.foo}" -> "foo"
        .replaceAll(/\${DATASET.([\w_]*)}/g, "$1")
        // "${bar.foo}" -> "bar.foo"
        // or "${bar}.foo" -> "bar.foo"
        .replaceAll(/\${([\w_.]*)}/g, "$1")
    );
  }

  return null;
}

export function semanticMeasureAggType(measure: HexSLTypes.Measure): string {
  if (NumericMeasureAggTypes.guard(measure.type)) {
    return measure.type;
  }

  return `CUSTOM`;
}

export function compactSemanticMeasureAggType(
  measure: HexSLTypes.Measure,
): string {
  if (NumericMeasureAggTypes.guard(measure.type)) {
    switch (measure.type) {
      case "sum":
        return "SUM";
      case "avg":
        return "AVG";
      case "min":
        return "MIN";
      case "max":
        return "MAX";
      case "count":
        return "CNT";
      case "countdistinct":
        return "CND";
      case "stddev":
        return "STD";
      case "stddevpop":
        return "STP";
      case "sumboolean":
        return "SBT";
      default:
        return "UNKNOWN";
    }
  }
  return `CSM`;
}
