// !!! IMPORTANT !!! This needs to be kept in sync with @hex/python-kernel-startup/python_kernel_startup/hex_mimetype.py
import {
  Array as RArray,
  Dictionary as RDict,
  Literal as RLiteral,
  Null as RNull,
  Record as RRecord,
  String as RString,
  Union as RUnion,
  Unknown as RUnknown,
  Static,
} from "runtypes";

import { ExportType } from "./enums";
import { TableStoreReference } from "./outputTypes";

// This is a "default" mimetype for binary files. Any binary files we have
// uploaded without a content type will have this default file type.
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#applicationoctet-stream
export const APPLICATION_OCTET_STREAM = "application/octet-stream" as const;

// Export mimetypes
export const HEX_MIMETYPE_PREFIX = "application/vnd.hex" as const;
export const HEX_EXPORT_MIMETYPE_PREFIX =
  `${HEX_MIMETYPE_PREFIX}.export` as const;
// We no longer produce exports of this mimetype, but we did in the past
export const CSV_EXPORT_MIMETYPE = `${HEX_EXPORT_MIMETYPE_PREFIX}+csv` as const;
// Instead we now export data in the parquet format typically
export const PARQUET_EXPORT_MIMETYPE =
  `${HEX_EXPORT_MIMETYPE_PREFIX}+parquet` as const;
// For data in the tablestore we produce an export that simply points to that data,
// which can be converted to CSV on-demand
export const TABLESTORE_EXPORT_MIMETYPE =
  `${HEX_EXPORT_MIMETYPE_PREFIX}+tablestore` as const;

export const isExportMimeType = (mimeType: string): boolean => {
  return mimeType.startsWith(HEX_EXPORT_MIMETYPE_PREFIX);
};

export interface ExportTypeInfo {
  extension: string;
  hexMimeType: string;
  mimeType: string;
}

export const EXPORT_TYPES: Record<ExportType, ExportTypeInfo> = {
  csv: {
    extension: "csv",
    hexMimeType: CSV_EXPORT_MIMETYPE,
    mimeType: "text/csv",
  },
  parquet: {
    extension: "parquet",
    hexMimeType: PARQUET_EXPORT_MIMETYPE,
    mimeType: APPLICATION_OCTET_STREAM,
  },
  tablestore: {
    extension: "tablestore",
    hexMimeType: TABLESTORE_EXPORT_MIMETYPE,
    mimeType: APPLICATION_OCTET_STREAM,
  },
};

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
const exportMimeTypes = Object.entries(EXPORT_TYPES).reduce<
  Record<string, ExportType>
>((map, [exportTypeKey, exportTypeValue]) => {
  map[exportTypeValue.hexMimeType] = exportTypeKey as ExportType;
  return map;
}, {});

export const getExportTypes = (
  outputContents: { mimeType: string }[],
): ExportType[] => {
  return outputContents
    .filter((outputContent) =>
      Object.keys(exportMimeTypes).includes(outputContent.mimeType),
    )
    .map((outputContent) => exportMimeTypes[outputContent.mimeType])
    .filter((exportType): exportType is ExportType => exportType != null);
};

export type ExportTypeAndStatus = {
  exportType: ExportType;
  exportFailureReason: string | null;
};
export const getExportTypesAndStatus = (
  outputContents: readonly { mimeType: string; contents: string }[],
): ExportTypeAndStatus[] => {
  return outputContents.flatMap((oc) => {
    const exportType = exportMimeTypes[oc.mimeType];
    if (exportType == null) {
      return [];
    }

    const exportContent = JSON.parse(oc.contents);
    if (!ExportOutputContents.guard(exportContent)) {
      return [];
    }

    return [
      {
        exportType,
        exportFailureReason: ExportOutputContentsFailure.guard(exportContent)
          ? exportContent.reason
          : null,
      },
    ];
  });
};

// Runtypes for the contents of export outputs

/** Legacy format: Just a string with an S3 key - this is how we used to output exports */
export const ExportOutputContentsLegacy = RString;

/** New format: A discriminated union with a failure reason if the export didn't succeed */
export const ExportOutputContentsSuccess = RRecord({
  success: RLiteral(true),
  exportKey: RString,
});
export type ExportOutputContentsSuccess = Static<
  typeof ExportOutputContentsSuccess
>;
export const ExportOutputContentsFailure = RRecord({
  success: RLiteral(false),
  reason: RString,
});
export const ExportOutputContentsNew = RUnion(
  ExportOutputContentsSuccess,
  ExportOutputContentsFailure,
);

export const BaseTableStoreExportContents = RRecord({
  sql: RString,
  parameters: RArray(RUnknown),

  // we don't have an easy way of getting the order of colummns in the entry, which isn't
  // a problem if we're just doing a "select *", but if we're applying renames we need to
  // list each column in order in our select
  columnIds: RArray(RString),
  columnRenames: RUnion(RDict(RString, RString), RNull),
});
export type BaseTableStoreExportContents = Static<
  typeof TableStoreExportContents
>;

/** For data present in the TableStore, we skip re-exporting and instead persist a pointer
 *  to the entry. These exports include a base query to run against the entry, which display
 *  tables use to specify the query for any configured filters, including the values of any
 *  parameters to those filters pulled out of the kernel scope and JSON serialized */
export const SingleTableStoreExportContents =
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- runtypes
  BaseTableStoreExportContents.extend({
    type: RLiteral("tablestore"),
    contentHash: RString,
    versionId: RString,
    sourceName: RString,
  });
export type SingleTableStoreExportContents = Static<
  typeof SingleTableStoreExportContents
>;

/** Export based on querying multiple table store entries. For this style of export, rather
 *  than specifying a `sourceName` for each entry to be bound to, it is expected that the
 *  query refers to entries using their `contentHash` as the identifier.
 */
export const MultiTableStoreExportContents =
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- runtypes
  BaseTableStoreExportContents.extend({
    type: RLiteral("tablestore-multi"),
    refs: RArray(TableStoreReference),
  });
export type MultiTableStoreExportContents = Static<
  typeof MultiTableStoreExportContents
>;

export const TableStoreExportContents = RUnion(
  SingleTableStoreExportContents,
  MultiTableStoreExportContents,
);
export type TableStoreExportContents = Static<typeof TableStoreExportContents>;

/** Exports can be in the legacy or new format */
export const ExportOutputContents = RUnion(
  ExportOutputContentsLegacy,
  ExportOutputContentsNew,
  TableStoreExportContents,
);
export type ExportOutputContents = Static<typeof ExportOutputContents>;

export const getExportKeyFromOutputContents = (
  outputContents: ExportOutputContents | undefined,
): string | undefined => {
  return ExportOutputContentsLegacy.guard(outputContents)
    ? outputContents
    : ExportOutputContentsSuccess.guard(outputContents)
      ? outputContents.exportKey
      : undefined;
};

// Metadata mimetypes
export const FILLED_DYNAMIC_VALUE_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.filleddynamicvalue+json` as const;
export const SQL_STATUS_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.sqlstatus+json` as const;
export const SQL_COMPILED_QUERY_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.sqlcompiledquery+json` as const;
export const WRITEBACK_STATUS_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.writebackstatus+json` as const;
export const PIVOT_TABLE_CONFIG_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.pivottableconfig+json` as const;
export const MAP_FILLED_DYNAMIC_VALUE_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.mapfilleddynamicvalue+json` as const;
export const COLUMN_AGGREGATIONS_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.columnaggregations+json` as const;
// Used to indicate there was a problem capturing scope
// Can indicate the we failed to capture a single value
// or that something broke w/ the comm
export const SCOPE_ERROR_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.scopeerror+json` as const;
// The state hydration prototype is based around persisting state to rehydrate
// in special metadata outputs of this type which are not sent to the client.
// This is somewhat of a hack but a necessary one as any alternative will
// require creating a new type of `TableReference` link to the state which will
// be an involved migration and out of scope for a prototype.
export const STATE_SLICE_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.stateslice+json` as const;
export const STATE_SLICE_SESSION_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.stateslicesession+json` as const;

export const isHiddenMimeType = (mimeType: string): boolean => {
  return (
    mimeType === STATE_SLICE_MIMETYPE ||
    mimeType === STATE_SLICE_SESSION_MIMETYPE
  );
};

export const METADATA_MIMETYPES = [
  FILLED_DYNAMIC_VALUE_MIMETYPE,
  MAP_FILLED_DYNAMIC_VALUE_MIMETYPE,
  SQL_STATUS_MIMETYPE,
  WRITEBACK_STATUS_MIMETYPE,
  PIVOT_TABLE_CONFIG_MIMETYPE,
  SQL_COMPILED_QUERY_MIMETYPE,
  SCOPE_ERROR_MIMETYPE,
  STATE_SLICE_MIMETYPE,
  STATE_SLICE_SESSION_MIMETYPE,
] as const;
export type MetadataMimeType = (typeof METADATA_MIMETYPES)[number];

export const isMetadataMimeType = (
  mimeType: string,
): mimeType is MetadataMimeType => {
  return METADATA_MIMETYPES.some((mt) => mt === mimeType);
};

// Custom hex display mimetypes
export const DISPLAY_TABLE_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.displaytable+json` as const;
export const OVERSIZE_OUTPUT_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.oversized-output+json` as const;
export const OUTPUTS_TRUNCATED_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.outputs-truncated+json` as const;
export const METRIC_CELL_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.metriccell+json` as const;
export const RICH_TEXT_CELL_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.richtext+json` as const;
// Mime-type for vega-fusion outputs. Represents a v5 Vega spec.
export const HEX_CHART_MIMETYPE = `${HEX_MIMETYPE_PREFIX}.chart+json` as const;
export const CUSTOM_ERROR_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.customerror+json` as const;
export const ARROW_TABLE_MIMETYPE =
  `${HEX_MIMETYPE_PREFIX}.arrowtable+json` as const;

// Custom mime-type for state transfer
export const HEX_STATE_MIMETYPE = `${HEX_MIMETYPE_PREFIX}.state+json` as const;

// Highest priority for rendering is on top
const DISPLAY_MIME_TYPE_PRIORITY = [
  CUSTOM_ERROR_MIMETYPE,
  "traceback",
  OUTPUTS_TRUNCATED_MIMETYPE,
  DISPLAY_TABLE_MIMETYPE,
  METRIC_CELL_MIMETYPE,
  RICH_TEXT_CELL_MIMETYPE,
  HEX_CHART_MIMETYPE,
  ARROW_TABLE_MIMETYPE,
  // MimeTypes pulled from:
  // https://github.com/jupyterlab/jupyterlab/blob/master/packages/rendermime/src/factories.ts
  // https://github.com/jupyterlab/jupyterlab/blob/master/packages/vega5-extension/src/index.ts
  "application/vnd.plotly.v1+json",
  "application/vnd.vega.v2+json",
  "application/vnd.vega.v3+json",
  "application/vnd.vega.v4+json",
  "application/vnd.vega.v5+json",
  "application/vnd.vegalite.v1+json",
  "application/vnd.vegalite.v2+json",
  "application/vnd.vegalite.v3+json",
  "application/vnd.vegalite.v4+json",
  "application/vnd.vegalite.v5+json",
  "text/html",
  "image/bmp",
  "image/png",
  "image/jpeg",
  "image/gif",
  // "text/latex", // Hex doesn't support this currently
  "text/markdown",
  "image/svg+xml",
  // It's important that OVERSIZE_OUTPUT_MIMETYPE stays above text/plain so that getPreferredMimeType selects
  // the oversize output in cases of oversized outputs
  // More context: https://github.com/hex-inc/hex/pull/16219
  OVERSIZE_OUTPUT_MIMETYPE,
  "text/plain",
  // Hex doesn't support these for now
  // "application/vnd.jupyter.stdout",
  // "application/vnd.jupyter.stderr",
  // "text/javascript",
  // "application/javascript",
] as const;
export type DisplayMimeType = (typeof DISPLAY_MIME_TYPE_PRIORITY)[number];

export const isDisplayMimeType = (
  mimeType: string,
): mimeType is DisplayMimeType => {
  return DISPLAY_MIME_TYPE_PRIORITY.some((mt) => mt === mimeType);
};

export const getMimeTypePriority = (mimeType: string): number => {
  if (isDisplayMimeType(mimeType)) {
    return DISPLAY_MIME_TYPE_PRIORITY.indexOf(mimeType);
  } else {
    return Number.MAX_SAFE_INTEGER;
  }
};

export const getPreferredMimeType = (
  mimeTypes: string[],
): string | undefined => {
  const orderedMimeTypes = mimeTypes.sort(
    (a, b) => getMimeTypePriority(a) - getMimeTypePriority(b),
  );
  return orderedMimeTypes[0];
};

const sanitizedMimeTypes: DisplayMimeType[] = [
  "text/plain",
  "text/markdown",
  OVERSIZE_OUTPUT_MIMETYPE,
  "traceback",
  CUSTOM_ERROR_MIMETYPE,
];

export const shouldSanitize = (mimeType: string): boolean => {
  return isDisplayMimeType(mimeType) && sanitizedMimeTypes.includes(mimeType);
};

export const ALLOWED_IMAGE_FILE_MIMETYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/svg+xml",
];

const ALLOWED_NON_IMAGE_FILE_MIMETYPES = ["text/csv", "text/plain"];

export const ALLOWED_PUBLIC_DOWNLOAD_FILE_MIMETYPES = [
  /* eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- combining multiple const arrays */
  ...ALLOWED_IMAGE_FILE_MIMETYPES,
  /* eslint-disable-next-line tree-shaking/no-side-effects-in-initialization --  combining multiple const arrays */
  ...ALLOWED_NON_IMAGE_FILE_MIMETYPES,
  APPLICATION_OCTET_STREAM,
];

export type MimeType = DisplayMimeType | MetadataMimeType;
