import {
  Array,
  Boolean,
  Literal,
  Optional,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

import {
  DateColumnDisplayFormat,
  DatetimeColumnDisplayFormat,
  NumberColumnDisplayFormat,
} from "../display-table/columnDisplayFormatTypes";
import { DisplayTableOutputColumnTypeLiteral } from "../display-table/outputTypes";
import { ExploreCustomTimeUnit } from "../explore/exploreCustomTimeUnit.js";
import { getNormalEnum } from "../runtypeEnums";

export const PivotFieldInputType = DisplayTableOutputColumnTypeLiteral;
export type PivotFieldInputType = Static<typeof PivotFieldInputType>;

export const PivotTruncateDateIntervalLiteral = Union(
  Literal("HOUR"),
  Literal("DAY"),
  Literal("WEEK"),
  Literal("MONTH"),
  Literal("QUARTER"),
  Literal("YEAR"),
);
export type PivotTruncateDateInterval = Static<
  typeof PivotTruncateDateIntervalLiteral
>;
export const PivotTruncateDateInterval = getNormalEnum(
  PivotTruncateDateIntervalLiteral,
);

export const PivotDisplayFormat = Union(
  DateColumnDisplayFormat,
  DatetimeColumnDisplayFormat,
  NumberColumnDisplayFormat,
);
export type PivotDisplayFormat = Static<typeof PivotDisplayFormat>;

export const PivotTruncateToUnit = Union(
  PivotTruncateDateIntervalLiteral,
  ExploreCustomTimeUnit,
);
export type PivotTruncateToUnit = Static<typeof PivotTruncateToUnit>;
export const PivotGroupByField = Record({
  field: String,
  fieldType: Optional(PivotFieldInputType),
  nameOverride: Optional(String),
  truncateTo: Optional(PivotTruncateToUnit),
  displayFormat: Optional(PivotDisplayFormat),
});
export type PivotGroupByField = Static<typeof PivotGroupByField>;

export const PivotAggregationLiteral = Union(
  Literal("COUNT"),
  Literal("COUNT_DISTINCT"),
  Literal("SUM"),
  Literal("AVERAGE"),
  Literal("MIN"),
  Literal("MAX"),
  Literal("MEDIAN"),
);
export type PivotAggregation = Static<typeof PivotAggregationLiteral>;
export const PivotAggregation = getNormalEnum(PivotAggregationLiteral);

export const PivotValueField = Record({
  field: String,
  fieldType: Optional(PivotFieldInputType),
  nameOverride: Optional(String),
  aggregation: PivotAggregationLiteral,
  displayFormat: Optional(PivotDisplayFormat),
});
export type PivotValueField = Static<typeof PivotValueField>;

export const PivotTableConfig = Record({
  rows: Array(PivotGroupByField),
  columns: Array(PivotGroupByField),
  values: Array(PivotValueField),
  // If true, display multiple values as rows. Ignored unless there are 2+ values
  valuesAsRows: Optional(Boolean),
});
export type PivotTableConfig = Static<typeof PivotTableConfig>;
