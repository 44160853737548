import { capitalize } from "lodash";
import type { LoggerInterface } from "vega";
import { Config } from "vega-lite";
import type {
  Aggregate,
  ArgmaxDef,
  ArgminDef,
} from "vega-lite/build/src/aggregate";
import type { BinParams } from "vega-lite/build/src/bin";
import type { FieldDefBase, ScaleMixins } from "vega-lite/build/src/channeldef";
import type {
  BinnedTimeUnit,
  LocalSingleTimeUnit,
  LocalTimeUnit,
  TimeUnit,
  TimeUnitParams,
  UtcTimeUnit,
} from "vega-lite/build/src/timeunit";

import { TimezoneName } from "../dateTypes";
import { ExploreCustomTimeUnit } from "../explore/exploreCustomTimeUnit.js";
import { typedObjectKeys } from "../utils";

/*
 * A whole bunch of functionality directly copied out of vega-lite in order to
 * get their `verbalTitleFormatter` implementation. Ideally, we could just import it directly
 * from its submodule, but we can't with our current Node.js setup because their file uses `import`.
 * We'll see if Vega-Lite will export it at the top-level of their package for us:
 * https://github.com/vega/vega-lite/pull/8189
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isObject(a: any): a is object {
  return a === Object(a);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isString(a: any): a is string {
  return typeof a === "string";
}

function isBinParams(
  bin: BinParams | boolean | "binned" | undefined | null,
): bin is BinParams {
  return isObject(bin);
}
function isBinning(
  bin: BinParams | boolean | "binned" | undefined | null,
): bin is BinParams | true {
  return bin === true || (isBinParams(bin) && !bin.binned);
}

function isUTCTimeUnit(t: string | undefined): t is UtcTimeUnit {
  return t?.startsWith("utc") ?? false;
}

export function isBinnedTimeUnitString(
  timeUnit: TimeUnit | BinnedTimeUnit | undefined,
): timeUnit is BinnedTimeUnit {
  return timeUnit?.startsWith("binned") ?? false;
}

export function getLocalTimeUnitFromUTCTimeUnit(t: UtcTimeUnit): LocalTimeUnit {
  return t.substring(3) as LocalTimeUnit;
}

export function normalizeTimeUnit(
  timeUnit: TimeUnit | BinnedTimeUnit | TimeUnitParams,
): TimeUnitParams | undefined {
  if (!timeUnit) {
    return undefined;
  }

  let params: TimeUnitParams;
  if (isString(timeUnit)) {
    if (isBinnedTimeUnitString(timeUnit)) {
      params = {
        unit: timeUnit.substring(6) as TimeUnit,
        binned: true,
      };
    } else {
      params = {
        unit: timeUnit,
      };
    }
  } else if (isObject(timeUnit)) {
    params = {
      ...timeUnit,
      ...(timeUnit.unit ? { unit: timeUnit.unit } : {}),
    };
  } else {
    throw new Error(`Unexpected time unit: ${timeUnit}`);
  }

  if (isUTCTimeUnit(params.unit)) {
    params.utc = true;
    params.unit = getLocalTimeUnitFromUTCTimeUnit(params.unit);
  }

  return params;
}

const LOCAL_SINGLE_TIMEUNIT_INDEX = {
  year: 1,
  quarter: 1,
  month: 1,
  week: 1,
  day: 1,
  dayofyear: 1,
  date: 1,
  hours: 1,
  minutes: 1,
  seconds: 1,
  milliseconds: 1,
} as const;

const TIMEUNIT_PARTS = typedObjectKeys(LOCAL_SINGLE_TIMEUNIT_INDEX);

function containsTimeUnit(fullTimeUnit: TimeUnit, timeUnit: TimeUnit): boolean {
  const index = fullTimeUnit.indexOf(timeUnit);

  if (index < 0) {
    return false;
  }

  // exclude milliseconds
  if (
    index > 0 &&
    timeUnit === "seconds" &&
    fullTimeUnit.charAt(index - 1) === "i"
  ) {
    return false;
  }

  // exclude dayofyear
  if (
    fullTimeUnit.length > index + 3 &&
    timeUnit === "day" &&
    fullTimeUnit.charAt(index + 3) === "o"
  ) {
    return false;
  }
  if (
    index > 0 &&
    timeUnit === "year" &&
    fullTimeUnit.charAt(index - 1) === "f"
  ) {
    return false;
  }

  return true;
}

export function getTimeUnitParts(timeUnit: TimeUnit): LocalSingleTimeUnit[] {
  return TIMEUNIT_PARTS.filter((part) => containsTimeUnit(timeUnit, part));
}

function isArgmaxDef(a: Aggregate | string): a is ArgmaxDef {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!a && !!(a as any)["argmax"];
}

function isArgminDef(a: Aggregate | string): a is ArgminDef {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!a && !!(a as any)["argmin"];
}

type FieldDef = Omit<FieldDefBase<string>, "timeUnit"> & {
  timeUnit?: FieldDefBase<string>["timeUnit"] | ExploreCustomTimeUnit;
};

export function verbalTitleFormatter(
  fieldDef: FieldDef,
  config: Config = {},
): string | undefined {
  const { aggregate, bin, field, timeUnit } = fieldDef;
  if (aggregate === "count") {
    return config.countTitle ?? "Count of Records";
  } else if (isBinning(bin)) {
    return `${field} (binned)`;
  } else if (timeUnit) {
    if (ExploreCustomTimeUnit.guard(timeUnit)) {
      return `${field} (${timeUnit.name})`;
    }
    const unit = normalizeTimeUnit(timeUnit)?.unit;
    if (unit) {
      return `${field} (${getTimeUnitParts(unit).join("-")})`;
    }
  } else if (aggregate) {
    if (isArgmaxDef(aggregate)) {
      return `${field} for max ${aggregate.argmax}`;
    } else if (isArgminDef(aggregate)) {
      return `${field} for min ${aggregate.argmin}`;
    } else {
      return `${capitalize(aggregate)} of ${field}`;
    }
  }
  return field;
}

export type FieldTitleFormatter = (
  fieldDef: FieldDef,
  config: Config,
) => string;

export const makeVegaAxisFormatter: (
  timezoneLabel: TimezoneName | undefined | null,
) => FieldTitleFormatter = (timezoneLabel) => (fieldDef, config) => {
  // The default title that VegaLite normally uses
  const defaultTitle = verbalTitleFormatter(fieldDef, config) ?? "";
  const timeUnit = fieldDef.timeUnit;
  if (timezoneLabel && timeUnit) {
    // for custom time units just use the timezone label
    const standardTimeUnit = !ExploreCustomTimeUnit.guard(timeUnit)
      ? normalizeTimeUnit(timeUnit)
      : undefined;

    // Vega will output in UTC if a utc timeUnit is provided or the scale is explictly "utc"
    const isUTC =
      standardTimeUnit?.utc === true ||
      (fieldDef as ScaleMixins).scale?.type === "utc";

    const timezone = isUTC ? "UTC" : timezoneLabel;

    // I think the default title for a timeUnit will always end in a paren,
    // such as "field (hours)". In this case, we don't want double parens, so we add
    // the timezone into the existing parens.
    if (defaultTitle.endsWith(")")) {
      return `${defaultTitle.slice(0, -1)}, ${timezone})`;
    }

    // But just in case the string doesn't end in parens, add our own set of parens
    return `${defaultTitle} (${timezone})`;
  }

  return defaultTitle;
};

export class VegaLogCollector implements LoggerInterface {
  private warningsLogs: string[] = [];
  private errorLogs: string[] = [];

  getWarningLogs(): readonly string[] {
    return this.warningsLogs;
  }

  getErrorLogs(): readonly string[] {
    return this.errorLogs;
  }

  // we don't really care about levels, so just a do-nothing impl
  level(_: number): this;
  level(): number;
  level(lvl?: unknown): number | this {
    if (lvl == null) return 0;
    return this;
  }

  error(msg: string): this {
    this.errorLogs.push(msg);
    return this;
  }

  warn(msg: string): this {
    this.warningsLogs.push(msg);
    return this;
  }

  // we don't care about collecting info or debug messages, so just skip
  info(): this {
    return this;
  }
  debug(): this {
    return this;
  }
}
