import type {
  ChannelDef,
  DatumDef,
  Field,
  FieldDefBase,
  SecondaryFieldDef,
  TypedFieldDef,
} from "vega-lite/build/src/channeldef";

import { VegaLiteSpec, VegaSpec } from "./types";
import { isVegaLiteSpec } from "./vegaSpecVersionTransformer";

// Copied from VegaLite because can't import it directly in our Node.JS builds unfortunately
export function isFieldDef<F extends Field>(
  channelDef: Partial<ChannelDef<F>> | FieldDefBase<F> | DatumDef<F, any>,
): channelDef is FieldDefBase<F> | TypedFieldDef<F> | SecondaryFieldDef<F> {
  return (
    !!channelDef &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (!!(channelDef as any)["field"] ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (channelDef as any)["aggregate"] === "count")
  );
}

export function vegaStringExpr(s: string): string {
  // Create representation of a string for use in a Vega expression.
  // Unlike escapeVegaString, we only need to escape double quotes in
  // this case. In particular, we don't want to escape periods in strings
  // that contain floats.
  // we also need to escape \n and \r because those cause Vega to throw
  // ILLEGALTOKEN errors
  const escaped = s
    .replaceAll('\\"', '\\\\"') // handle escaped quotes (reprocessed on next line!)
    .replaceAll('"', '\\"')
    .replaceAll("\\\n", "\\\\\n") // handle escaped LF (reprocessed on next line!)
    .replaceAll("\n", "\\n")
    .replaceAll("\\\r", "\\\\\r") // handle escaped CR (reprocessed on next line!)
    .replaceAll("\r", "\\r");
  return `"${escaped}"`;
}

export function escapeVegaString(str: string): string {
  return str
    .replaceAll(".", "\\.")
    .replaceAll("'", "\\'")
    .replaceAll('"', '\\"')
    .replaceAll("[", "\\[")
    .replaceAll("]", "\\]")
    .replaceAll("\n", "\\n")
    .replaceAll("\r", "\\r")
    .replaceAll("\t", "\\t")
    .replaceAll("\b", "\\b");
}

export function unescapeVegaString(str: string): string {
  // this function is risky:
  // it should only be applied where the above has been applied first
  // otherwise it'll unwrap some other, previous wrapping
  return str
    .replaceAll("\\.", ".")
    .replaceAll("\\'", "'")
    .replaceAll('\\"', '"')
    .replaceAll("\\[", "[")
    .replaceAll("\\]", "]")
    .replaceAll("\\n", "\n")
    .replaceAll("\\r", "\r")
    .replaceAll("\\t", "\t")
    .replaceAll("\\b", "\b");
}

/**
 * Guards strings for Vega given outstanding bug referenced in docstring for
 * patchVegaEscapeIssue below.
 */
const JSONEscapeString = (str: string): string => {
  const strJSON = JSON.stringify(str);
  return strJSON.substr(1, strJSON.length - 2);
};

/**
 * For some reason in addition to normal JSON escaping,
 * fields also need to escape single quotes, periods, brackets while titles don't.
 *
 * This results in an ugly default title, but its fixible if the
 * user provides a better title
 *
 * See https://github.com/vega/vega-lite/issues/7790
 */
const escapeFieldString = (str: string): string => {
  return escapeVegaString(unescapeVegaString(JSONEscapeString(str)));
};

/**
 * Vega breaks if you populate some fields on an encoding channel that contains
 * quotes, and potentially other characteres necessitating escape. Rather than
 * botch these values as we persist them to the back end, we patch them in here
 * prior to vegaEmbed so we can simply remove this abomination once the library
 * has been updated.
 *
 * See:
 * - https://github.com/vega/vega-lite/issues/7460
 * - https://github.com/vega/vega-lite/issues/7790
 *
 * TODO: Remove this as soon as the above are resolved.
 */
export const patchVegaEscapeIssue = <T extends VegaLiteSpec | VegaSpec>(
  spec: T,
): T => {
  // for now, we only patch things in our expected Vega-Lite spec format from chart cells
  // However, let other more complex vega specs through in case they come from using the `HexChart()` API
  if (!isVegaLiteSpec(spec) || spec.layer == null) {
    return spec;
  }

  return {
    ...spec,
    layer: spec.layer.map((layer) => {
      return {
        ...layer,
        encoding: Object.entries(layer?.encoding ?? {})
          .map(([key, encodingDef]) => {
            if (isFieldDef(encodingDef)) {
              return [
                key,
                {
                  ...encodingDef,
                  ...(encodingDef.field != null &&
                  typeof encodingDef.field === "string"
                    ? {
                        field: escapeFieldString(encodingDef.field),
                      }
                    : {}),
                  ...(encodingDef.title != null &&
                  typeof encodingDef.title === "string"
                    ? {
                        title: JSONEscapeString(encodingDef.title),
                      }
                    : {}),
                },
              ];
            } else {
              return [key, encodingDef];
            }
          })
          // vega types make this _really_ hard to do in a typesafe way
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .reduce<any>((acc, [key, encodingDef]) => {
            acc[key as string] = encodingDef;
            return acc;
          }, {}),
      };
    }),
  };
};
