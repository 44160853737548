import {
  Literal,
  Optional as ROptional,
  Record as RRecord,
  String as RString,
  Union as RUnion,
  Static,
} from "runtypes";

export type PublishedAppColors = {
  backgroundColor: string;
  accentColor: string;
};

export const PROVIDED_CUSTOM_FONTS = [
  {
    value: "default",
    title: "IBM Plex Sans",
  },
  {
    value: "roboto",
    title: "Roboto",
  },
  {
    value: "open-sans",
    title: "Open Sans",
  },
  {
    value: "noto-sans",
    title: "Noto Sans",
  },
] as const;
export type ProvidedFontId = (typeof PROVIDED_CUSTOM_FONTS)[number]["value"];

export const DEFAULT_FONT_URL: Record<
  Exclude<ProvidedFontId, "default">,
  string
> = {
  roboto:
    "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap",
  "open-sans":
    "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap",
  "noto-sans":
    "https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap",
};

export type OrgPublishedAppThemeStyle = {
  // Note any addition to this type must be optional as this is stored in our DB
  dark: Partial<PublishedAppColors>;
  light: Partial<PublishedAppColors>;
  font: ProvidedFontId | "custom";
};

const SelfHostedFontVariant = RRecord({
  name: RString,
  format: RString,
  key: RString,
});

type SelfHostedFontVariant = Static<typeof SelfHostedFontVariant>;

export const OrgCustomFontDescriptor = RUnion(
  RRecord({
    type: Literal("self-hosted"),
    fonts: RRecord({
      regular: SelfHostedFontVariant,
      bold: ROptional(SelfHostedFontVariant),
      italic: ROptional(SelfHostedFontVariant),
    }),
  }),
  RRecord({
    type: Literal("google"),
    url: RString,
  }),
);

export type OrgCustomFontDescriptor = Static<typeof OrgCustomFontDescriptor>;

export type CustomFontFormat = "otf" | "woff" | "ttf";

/**
 * Returns the name of the font used in the css font family name
 */
export const getCustomFontFamilyName = (
  fontType: OrgPublishedAppThemeStyle["font"],
  fontDescriptor?: OrgCustomFontDescriptor | null,
): string | null => {
  if (fontType === "custom") {
    return fontDescriptor != null
      ? fontDescriptor.type === "google"
        ? getGoogleFontFamilyNameFromUrl(fontDescriptor.url)
        : null
      : null;
  } else if (fontType !== "default") {
    return getGoogleFontFamilyNameFromUrl(DEFAULT_FONT_URL[fontType]);
  } else {
    return null;
  }
};

/**
 * Returns the url of the font that should be loaded
 */
export const getCustomFontUrl = (
  fontType: OrgPublishedAppThemeStyle["font"],
  fontDescriptor?: OrgCustomFontDescriptor | null,
): string | null => {
  if (fontType === "custom") {
    return fontDescriptor != null
      ? fontDescriptor.type === "google"
        ? fontDescriptor.url
        : null
      : null;
  } else if (fontType !== "default") {
    return DEFAULT_FONT_URL[fontType];
  } else {
    return null;
  }
};

function getGoogleFontFamilyNameFromUrl(url: string): string | null {
  try {
    const urlObj = new URL(url);
    const family = urlObj.searchParams.get("family");
    return family?.split(":")[0] ?? null;
  } catch (_) {
    return null;
  }
}

export function validateGoogleFontUrlOrGetError(url: string): string | null {
  // example font url looks like: https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900
  // We should validate that: it's a url, it's fonts.googleapis.com/css2 and has a fmaily key.
  try {
    const urlObj = new URL(url);
    if (!url.startsWith("https://fonts.googleapis.com/css2")) {
      return "URL must start with https://fonts.googleapis.com/css2";
    } else if (
      urlObj.searchParams.get("family") == null ||
      urlObj.searchParams.get("family") === ""
    ) {
      return "URL must have a family key";
    } else if (urlObj.searchParams.getAll("family").length > 1) {
      return "URL must only have a single family key";
    }
    // Url is ok!
    return null;
  } catch (_) {
    return "Not a valid URL";
  }
}
