import { Boolean, Record, Static } from "runtypes";

export const UserSettings = Record({
  wordWrap: Record({
    markdown: Boolean,
    sql: Boolean,
    code: Boolean,
  }),
  lineNumbers: Record({
    markdown: Boolean,
    sql: Boolean,
    code: Boolean,
  }),
  relativeLineNumbers: Boolean,
  magicTypeahead: Record({
    magicTosAcceptedJuly2024: Boolean,
    markdown: Boolean,
    sql: Boolean,
    code: Boolean,
  }),
});
export type UserSettings = Static<typeof UserSettings>;

export const PartialUserSettings = UserSettings.asPartial();
export type PartialUserSettings = Static<typeof PartialUserSettings>;

export const DEFAULT_USER_SETTINGS: UserSettings = {
  wordWrap: {
    markdown: true,
    sql: false,
    code: false,
  },
  lineNumbers: {
    markdown: false,
    sql: true,
    code: true,
  },
  relativeLineNumbers: false,
  magicTypeahead: {
    /**
     * If Magic is enabled for an org, a user can individually accept TOS
     * or an admin can on behalf of an entire org.
     */
    magicTosAcceptedJuly2024: false,
    markdown: false,
    sql: false,
    // defaulting this to true is safe since it's only enabled if an
    // admin has turned it on for the entire org anyway.
    code: true,
  },
};
