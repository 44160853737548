import * as r from "runtypes";

export const Cursor = r.String.withBrand("Cursor");
export type Cursor = r.Static<typeof Cursor>;

export const NullableCursor = Cursor.nullable();

export const OrderByDirection = r.Union(r.Literal("ASC"), r.Literal("DESC"));
export type OrderByDirection = r.Static<typeof OrderByDirection>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const OrderBy = <A>(attribute: r.Runtype<A>) =>
  r.Array(
    r.Record({
      attribute,
      direction: OrderByDirection,
      reverseNullsPlacement: r.Optional(r.Boolean),
    }),
  );
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export type OrderBy<A> = r.Static<ReturnType<typeof OrderBy<A>>>;

export const GroupsOrderByAttribute = r.Union(
  r.Literal("name"),
  r.Literal("createdDate"),
);
export type GroupsOrderByAttribute = r.Static<typeof GroupsOrderByAttribute>;

export const GroupsOrderBy = OrderBy(GroupsOrderByAttribute);
export type GroupsOrderBy = r.Static<typeof GroupsOrderBy>;

export const UsersOrderByAttribute = r.Union(
  r.Literal("name"),
  r.Literal("email"),
  r.Literal("orgRole"),
  r.Literal("lastActiveDate"),
);
export type UsersOrderByAttribute = r.Static<typeof UsersOrderByAttribute>;

export const UsersOrderBy = OrderBy(UsersOrderByAttribute);
export type UsersOrderBy = r.Static<typeof UsersOrderBy>;

export const SchedulesOrderByAttribute = r.Union(
  r.Literal("project_name"),
  r.Literal("scheduler"),
  r.Literal("latest_run"),
  r.Literal("cadence"),
  r.Literal("last_updated"),
);
export type SchedulesOrderByAttribute = r.Static<
  typeof SchedulesOrderByAttribute
>;

export const MagicEventsOrderByAttributes = r.Union(r.Literal("created_date"));
export type MagicEventsOrderByAttributes = r.Static<
  typeof MagicEventsOrderByAttributes
>;

export const SchedulesOrderBy = OrderBy(SchedulesOrderByAttribute);
export type SchedulesOrderBy = r.Static<typeof SchedulesOrderBy>;

/**
 * Attributes to support bidirectional sorting of a Hexes paginated query.
 *
 * This type currently mirrors @HexOrder (currently supported sort attributes). HexOrder
 * will be removed once all paginated Hex calls use this new attribute type.
 */
export const HexesOrderByAttribute = r.Union(
  r.Literal("LAST_EDIT"),
  r.Literal("LAST_PUBLISH"),
  r.Literal("RECENTLY_VIEWED"),
  r.Literal("LAST_SEVEN_DAYS_VIEW_COUNT"),
  r.Literal("LAST_FOURTEEN_DAY_VIEW_COUNT"),
  r.Literal("LAST_THIRTY_DAY_VIEW_COUNT"),
  r.Literal("TOTAL_VIEW_COUNT"),
  r.Literal("CREATED_AT"),
  r.Literal("ARCHIVED_AT"),
  r.Literal("CREATOR"),
  r.Literal("OWNER"),
  r.Literal("CATEGORY"),
  r.Literal("STATUS"),
  r.Literal("MY_ACCESS"),
  r.Literal("APP_ONLY_RECENTLY_VIEWED"),
  /**
   * The default order by attribute for Hexes, when no order by attribute is provided.
   */
  r.Literal("PROJECT_NAME"),
);
export type HexesOrderByAttribute = r.Static<typeof HexesOrderByAttribute>;

export const HexesOrderBy = OrderBy(HexesOrderByAttribute);
export type HexesOrderBy = r.Static<typeof HexesOrderBy>;

export const CollectionHexLinksOrderByAttribute = r.Union(
  // extracts each of the attributes from HexesOrderByAttribute as literals, so they can be used here.
  ...HexesOrderByAttribute.alternatives,

  r.Literal("COLLECTION_LINK_PROJECT_ROLE"),
  r.Literal("PROJECT_ACCESS"),
);

export type CollectionHexLinksOrderByAttribute = r.Static<
  typeof CollectionHexLinksOrderByAttribute
>;

export const CollectionHexLinksOrderBy = OrderBy(
  CollectionHexLinksOrderByAttribute,
);
export type CollectionHexLinksOrderBy = r.Static<
  typeof CollectionHexLinksOrderBy
>;

export const DockerImageTagOrderByAttribute = r.Union(
  r.Literal("last_hex_pull_attempt_date"),
  r.Literal("last_pushed_date"),
);
export type DockerImageTagOrderByAttribute = r.Static<
  typeof DockerImageTagOrderByAttribute
>;

export const DockerImageTagOrderBy = OrderBy(DockerImageTagOrderByAttribute);
export type DockerImageTagOrderBy = r.Static<typeof DockerImageTagOrderBy>;

export const UserNotificationOrderByAttribute = r.Union(
  r.Literal("createdDate"),
);
export type UserNotificationOrderByAttribute = r.Static<
  typeof UserNotificationOrderByAttribute
>;

export const HexReferencesOrderByAttribute = r.Union(
  r.Literal("NAME"),
  r.Literal("DRAFT_VERSION"),
  r.Literal("PUBLISHED_VERSION"),
  /**
   * The default order by attribute for HexReferenceV2s, when no order by attribute is provided.
   */
  r.Literal("LAST_EDIT"),
);
export type HexReferencesOrderByAttribute = r.Static<
  typeof HexReferencesOrderByAttribute
>;

export const HexReferencesOrderBy = OrderBy(HexReferencesOrderByAttribute);
export type HexReferencesOrderBy = r.Static<typeof HexReferencesOrderBy>;

/**
 * A cursor string used with `before` and `after` parameters to paginate through a list
 * of items on the API.
 */
export type ApiPaginationCursor = string;
export interface ApiConnectionArgs {
  before: ApiPaginationCursor | null;
  after: ApiPaginationCursor | null;
  first: number | null;
  last: number | null;
}
