import { v7, version } from "uuid";

export function uuid(): string {
  return v7();
}

// Generates a random uuid and then returns the last N characters
export function randomString(length: number): string {
  return partialUuid(uuid(), length);
}

// Returns the a N characters of a UUID to safely generate a semi-unique ID when you dont want a whole UUID
export function partialUuid(initialUuid: string, length: number): string {
  if (length < 1 || length > 32) {
    throw new Error("Length must be between 1 and 32");
  }
  let fromFront;
  try {
    // much of our ids are already v4 and we can't change them, and some code already uses the first N characters,
    // so if we were to change them to use the last N characters, we'd break those assumptions in some cases
    // for uuid v7s, we can't use the first N characters, so we use the last N characters instead
    fromFront = version(initialUuid) === 4 ? true : false;
  } catch (e: unknown) {
    if (e instanceof Error && e.message.includes("Invalid UUID")) {
      // if it is not a uuid, use the length as is, it's probably for testing
      fromFront = true;
    } else {
      throw e;
    }
  }
  const strippedUUID = initialUuid.replaceAll("-", "");
  if (fromFront) {
    return strippedUUID.slice(0, length);
  } else {
    return strippedUUID.slice(-length);
  }
}
