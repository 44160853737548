import { groupBy } from "lodash";

import { ExploreUserJoin, ExploreUserJoinTable } from "./explore/types.js";
import { tablePathToDatasetName } from "./hql/hqlNextUtils.js";
import { DataSourceTableId, SemanticDatasetName } from "./idTypeBrands.js";
import { getSemanticDatasetTree } from "./semantic-layer/semanticGraph.js";
import { DataSourceTableConfig } from "./sql/dataSourceTableConfig.js";
import { typedKeyBy, typedObjectValues } from "./utils/typedObjects.js";

export type SemanticDatasetNode = {
  name: SemanticDatasetName;
  pathToNode: SemanticDatasetName[];
};

export type SemanticDatasetTree = Record<
  SemanticDatasetName,
  SemanticDatasetNode[]
>;

/**
 * Given a query path and a tree, returns the dataset corresponding to that
 * query path. This can be done simply by finding the node with the same
 * `pathToNode`
 */
export const getQueryPathOfDatasetFromTree = (
  tree: SemanticDatasetTree,
  datasetName: SemanticDatasetName,
): SemanticDatasetName[] | undefined => {
  let queryPath;
  for (const nodes of typedObjectValues(tree)) {
    for (const node of nodes) {
      if (node.name === datasetName) {
        if (queryPath != null) {
          // if there are multiple ways to query a table then we skip
          return undefined;
        }
        queryPath = node.pathToNode;
      }
    }
  }
  return queryPath;
};

/**
 * Given explore join information, returns the semantic join tree. For now this
 * assume that the input is a data source table.
 */
export const getJoinTreeForExplore = ({
  joins,
  startingTable,
  tables,
}: {
  startingTable: DataSourceTableConfig;
  tables: ExploreUserJoinTable[];
  joins: ExploreUserJoin[];
}): {
  tree: SemanticDatasetTree;
  rootNodes: SemanticDatasetNode[];
} => {
  const tableIds: DataSourceTableId[] = [];

  tableIds.push(startingTable.dataSourceTableId);

  const ids = tables
    .map((t) => t.dataSourceTableId)
    // The `tables` section of the spec might contain info about the base table
    // so we need to filter it out since we included it above.
    .filter((id) => id !== startingTable?.dataSourceTableId);
  tableIds.push(...ids);

  const specTablesById = typedKeyBy(tables ?? [], "dataSourceTableId");
  const joinsBySource = groupBy(joins ?? [], (j) => j.sourceTable.name);

  const tableMapping = tableIds.map((tableId) => ({
    name: specTablesById[tableId]!.name,
    properties: {
      joins: (joinsBySource[specTablesById[tableId]!.name] ?? []).map((j) => ({
        target: j.targetTable.name,
      })),
    },
  }));
  return getSemanticDatasetTree(
    tablePathToDatasetName(startingTable.pathToTable, tables),
    tableMapping,
  );
};
